const LinkedinIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/General">
        <path
          d="M26.2283 4H5.77167C5.30179 4 4.85116 4.18666 4.51891 4.51891C4.18666 4.85116 4 5.30179 4 5.77167V26.2283C4 26.6982 4.18666 27.1488 4.51891 27.4811C4.85116 27.8133 5.30179 28 5.77167 28H26.2283C26.6982 28 27.1488 27.8133 27.4811 27.4811C27.8133 27.1488 28 26.6982 28 26.2283V5.77167C28 5.30179 27.8133 4.85116 27.4811 4.51891C27.1488 4.18666 26.6982 4 26.2283 4ZM11.1533 24.445H7.545V12.9833H11.1533V24.445ZM9.34667 11.395C8.93736 11.3927 8.53792 11.2692 8.19873 11.0401C7.85955 10.811 7.59584 10.4865 7.44088 10.1077C7.28591 9.72885 7.24665 9.31259 7.32803 8.91145C7.40941 8.51032 7.6078 8.14228 7.89816 7.85378C8.18851 7.56529 8.55782 7.36927 8.95947 7.29046C9.36112 7.21165 9.77711 7.25359 10.155 7.41099C10.5328 7.56838 10.8555 7.83417 11.0825 8.17481C11.3094 8.51546 11.4303 8.91569 11.43 9.325C11.4339 9.59903 11.3825 9.87104 11.279 10.1248C11.1755 10.3786 11.022 10.6089 10.8276 10.8021C10.6332 10.9952 10.4018 11.1473 10.1474 11.2492C9.893 11.351 9.62067 11.4006 9.34667 11.395ZM24.4533 24.455H20.8467V18.1933C20.8467 16.3467 20.0617 15.7767 19.0483 15.7767C17.9783 15.7767 16.9283 16.5833 16.9283 18.24V24.455H13.32V12.9917H16.79V14.58H16.8367C17.185 13.875 18.405 12.67 20.2667 12.67C22.28 12.67 24.455 13.865 24.455 17.365L24.4533 24.455Z"
          id="Vector"
        />
      </g>
    </svg>
  );
};

export default LinkedinIcon;
