import React from "react";
import { FunctionComponent, HTMLAttributes } from "react";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant";
import { getOrgConfigByTenantId } from "@/utils/org";

type Props = HTMLAttributes<HTMLAnchorElement>;

const TermsAndConditionsButton: FunctionComponent<Props> = ({ className, children, ...rest }) => {
  //
  const tenant = useRecoilValue(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);
  const link = config.termsAndConditionsLink;
  //
  return (
    <a className={className} {...rest} href={link} rel="noopener noreferrer" target="_blank">
      {children ?? "Terms and Conditions"}
    </a>
  );
};

export default TermsAndConditionsButton;
