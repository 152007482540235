import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import React, { useMemo } from "react";
import { useRecoilValue } from "recoil";

import Container from "@/components/Container";
import FacebookIcon from "@/components/Icons/FacebookIcon";
import InstagramIcon from "@/components/Icons/InstagramIcon";
import LinkedinIcon from "@/components/Icons/LinkedinIcon";
import LinktreeIcon from "@/components/Icons/LinktreeIcon";
import XIcon from "@/components/Icons/XIcon";
import tenantAtom from "@/recoil/tenant";

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

const GeneralFooter: React.FunctionComponent = () => {
  const tenant = useRecoilValue(tenantAtom);
  // @TODO: review if this is correct, or should we use tenant config
  // @NOTICE: Titanic also has a different licenseLink config value
  const licenseLink = `https://${STORAGE_URL}/documents/content-licensing/Artevault_Content+Licensing+Agreement.pdf`;

  const renderSocialIcons = useMemo(() => {
    const socials = [
      {
        iconComponent: FacebookIcon,
        link: "https://www.facebook.com/profile.php?id=100084952281126",
      },
      {
        iconComponent: XIcon,
        link: "https://x.com/ArtifactLabs_",
      },
      {
        iconComponent: InstagramIcon,
        link: "https://www.instagram.com/ArtifactLabs_",
      },
      {
        iconComponent: LinkedinIcon,
        link: "https://www.linkedin.com/company/artifactlabscom/",
      },
      { iconComponent: LinktreeIcon, link: "https://linktr.ee/artifactlabs" },
    ];
    return socials.map((each, index) => {
      const SocialIcon = each.iconComponent;
      return (
        <a href={each.link} key={index} target="_blank">
          <SocialIcon className="h-[24px] w-[24px] fill-tenant-footer-social-icon md:h-[32px] md:w-[32px]" />
        </a>
      );
    });
  }, []);

  return (
    <div className={clsMerge("bg-tenant-footer")}>
      <Container>
        <div className="flex flex-col flex-wrap gap-[40px] px-[15px] py-[40px] text-white md:px-[40px] md:py-[64px]">
          <div className="flex flex-col justify-between gap-[48px] md:flex-row">
            {/* Logo */}
            <div className="flex h-[48px] justify-center md:h-[72px]">
              {tenant.settings.footerIconPath && (
                <Image alt="" className="object-contain" src={tenant.settings.footerIconPath} />
              )}
            </div>

            {/* Socials */}
            <div className="flex flex-row items-end justify-center gap-[16px]">
              {renderSocialIcons}
            </div>
          </div>

          <div className="custom-theme-footer flex flex-col-reverse justify-between gap-[16px] text-sm font-normal leading-normal md:flex-row">
            {/* Copyright */}
            <div className="flex justify-center text-center">
              © 2024 {tenant.settings.fullName}
            </div>

            {/* Licences */}
            <div className="flex flex-row justify-center gap-[24px]">
              {/*<div>Licenses</div>*/}
              <a href={licenseLink} target="_blank">
                Licensing Terms
              </a>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default GeneralFooter;
