/* eslint-disable @next/next/no-html-link-for-pages */
import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import React, { useState } from "react";
import { useRecoilValue } from "recoil";

import { DynamicUserInfo, DynamicVault } from "./dynamics";

import Container from "@/components/Container";
import HamburgerBotton from "@/components/HamburgerButton";
import HeaderMenu from "@/components/HeaderMenu";
import LoginButton from "@/components/LoginButton";
import { useWindowResize } from "@/hooks/useWindowResize";
import tenantAtom from "@/recoil/tenant";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";
import { zHeader } from "@/utils/zIndex";
import ArtifactlabsLogo from "@public/logos/artifactlabs.png";
const Header: React.FunctionComponent = () => {
  const tenant = useRecoilValue(tenantAtom);
  const isMobile = useWindowResize();

  const { domain } = getOrgAuth0ConfigByTenantId(tenant?.id);

  const { isAuthenticated, isLoading } = useAuthState(domain);
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false);

  return (
    <div className={clsMerge("sticky top-0 bg-white", zHeader.className)}>
      <Container>
        <header className="bg-white">
          <div className="tenant-header grid h-full w-full grid-cols-[1fr_auto_1fr] items-center gap-4">
            {/* Logo */}
            <div className="flex items-center">
              <a className="flex items-center justify-center gap-3" href="/">
                {tenant.settings.withLogo && !!tenant.settings.iconPath && (
                  <Image
                    alt=""
                    objectFit="cover"
                    src={tenant.settings.iconPath}
                    style={{
                      width: isMobile
                        ? `${tenant.settings.mobileWidth ?? (tenant.settings.width ?? 52) * 0.8}px`
                        : `${tenant.settings.width ?? 52}px`,
                    }}
                  />
                )}
                {tenant.settings.withArtifactLabsLogo && (
                  <Image
                    alt=""
                    className="p-0.5"
                    objectFit="cover"
                    src={ArtifactlabsLogo}
                    style={{
                      width: isMobile ? "32px" : "40px",
                    }}
                  />
                )}
              </a>
            </div>

            {/* Menus - Centered */}
            <div className="flex justify-center">{!isMobile && <HeaderMenu mode="desktop" />}</div>

            {/* Actions */}
            <div className="flex h-[32px] flex-row-reverse items-center gap-4">
              {!isLoading && (
                <div className="hidden justify-end md:flex">
                  {isAuthenticated ? (
                    <div className="flex items-center justify-center gap-6 ">
                      <DynamicVault />
                      <DynamicUserInfo />
                    </div>
                  ) : (
                    <LoginButton />
                  )}
                </div>
              )}

              {isMobile && (
                <div className="pl:20 flex items-center justify-center gap-3">
                  {isAuthenticated && <DynamicVault />}
                  <HamburgerBotton
                    isOpen={(state: boolean) => {
                      setIsHamburgerMenuOpen(state);
                    }}
                    state={isHamburgerMenuOpen}
                  />
                  <HeaderMenu
                    isAuthenticated={isAuthenticated}
                    isMenuOpen={isHamburgerMenuOpen}
                    mode="mobile"
                    onMenuClose={() => {
                      setIsHamburgerMenuOpen(false);
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </header>
      </Container>
    </div>
  );
};

export default Header;
