import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";
export interface Props {
  className?: String;
}

const LogoutButton: React.FunctionComponent<Props> = ({ className }) => {
  const tenant = useRecoilValue(tenantAtom);
  const { domain } = getOrgAuth0ConfigByTenantId(tenant?.id);
  const { logout } = useAuthState(domain);

  const handleLogout = async () => {
    await logout("/");
  };

  return (
    <>
      <button
        className={clsMerge(
          "inline-flex h-[40px] w-full flex-col items-center justify-center rounded-[8px] border-[1px] px-[16px] text-sm font-medium leading-[16px] tracking-[.02em]",
          "border-tenant-primary-default text-tenant-primary-default hover:border-tenant-primary-light hover:text-tenant-primary-light",
          className,
        )}
        onClick={handleLogout}
      >
        Sign Out
      </button>
    </>
  );
};

export default LogoutButton;
