const LinktreeIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/General">
        <path
          d="M5.6521 11.8963H11.9441L7.4461 7.63702L9.9161 5.14377L14.1801 9.48099V3.2998H17.8721V9.48099L22.1361 5.14377L24.6061 7.63702L20.1081 11.8704H26.4001V15.3765H20.0821L24.5801 19.7137L22.1101 22.155L16.0001 16.0517L9.8901 22.181L7.4201 19.7137L11.9181 15.3765H5.6001V11.8963H5.6521ZM14.2061 20.389H17.8981V28.6998H14.2061V20.389Z"
          id="Vector"
        />
      </g>
    </svg>
  );
};

export default LinktreeIcon;
