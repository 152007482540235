import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent, HTMLAttributes } from "react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  clean?: boolean;
}

const Container: FunctionComponent<Props> = ({ className, children, ...rest }) => {
  return (
    <div className={clsMerge("mx-auto w-full max-w-[1440px]", className)} {...rest}>
      {children}
    </div>
  );
};

export default Container;
