const XIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/General">
        <path
          d="M23.2004 4.68018H27.0401L18.6516 14.2676L28.52 27.314H20.7931L14.7412 19.4015L7.81639 27.314H3.97444L12.9467 17.0591L3.47998 4.68018H11.403L16.8734 11.9126L23.2004 4.68018ZM21.8529 25.0158H23.9804L10.2469 6.85767H7.9638L21.8529 25.0158Z"
          id="Vector"
        />
      </g>
    </svg>
  );
};

export default XIcon;
