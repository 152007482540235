const FacebookIcon = ({ ...props }) => {
  return (
    <svg
      fill="none"
      height="32"
      viewBox="0 0 32 32"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Icons/General">
        <path
          d="M15.9996 2.71338C8.63598 2.71338 2.6665 8.68286 2.6665 16.0464C2.6665 22.2991 6.97148 27.546 12.7788 28.987V20.121H10.0296V16.0464H12.7788V14.2907C12.7788 9.75271 14.8327 7.64928 19.288 7.64928C20.1328 7.64928 21.5904 7.81514 22.1866 7.98047V11.6737C21.872 11.6407 21.3253 11.6241 20.6464 11.6241C18.4603 11.6241 17.6155 12.4524 17.6155 14.6054V16.0464H21.9707L21.2224 20.121H17.6155V29.2819C24.2175 28.4846 29.3332 22.8634 29.3332 16.0464C29.3326 8.68286 23.3632 2.71338 15.9996 2.71338Z"
          id="Vector"
        />
      </g>
    </svg>
  );
};

export default FacebookIcon;
