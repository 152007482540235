import { clsMerge } from "@artifactlabs/shared-react-utils";
import {
  FunctionComponent,
  HTMLAttributes,
  LabelHTMLAttributes,
  useState,
  useCallback,
  useEffect,
  useRef,
} from "react";

interface FormInputWithLabelProps {
  label: string;
  inlineHelperText?: string;
  error?: string;
  isOptional?: boolean;
  labelProps?: LabelHTMLAttributes<HTMLLabelElement>;
  inputProps?: any;
  errorProps?: HTMLAttributes<HTMLDivElement>;
  mode?: "default" | "label-on-border";
}

const FormInputWithLabel: FunctionComponent<FormInputWithLabelProps> = ({
  label,
  inlineHelperText,
  error,
  isOptional = false,
  labelProps,
  inputProps,
  errorProps,
  mode,
}) => {
  const { className: labelClassNames, ...labelRestProps } = labelProps ?? {};
  const {
    className: inputClassName,
    value: inputValue,
    onChange,
    ...inputRestProps
  } = inputProps ?? {};
  const { className: errorClassName, ...errorRestProps } = errorProps ?? {};

  const [onFocus, setOnFocus] = useState(false);
  const [hasValue, setHasValue] = useState(Boolean(inputValue));

  // Create a stable reference to the blur handler
  const handleBlur = useCallback(() => {
    setOnFocus(false);
  }, []);

  // Handle cleanup of event listeners
  useEffect(() => {
    let currentInput: HTMLInputElement | null = null;

    // Create the cleanup function that we'll use both in addBlurListener and effect cleanup
    const removeCurrentListener = () => {
      if (currentInput) {
        currentInput.removeEventListener("blur", handleBlur);
        currentInput = null;
      }
    };

    // Function to add blur listener
    const addBlurListener = (element: HTMLInputElement) => {
      // Remove any existing listener first
      removeCurrentListener();
      // Add new listener
      currentInput = element;
      element.addEventListener("blur", handleBlur);
    };

    // Store the functions in ref for access from event handlers
    functionRef.current = {
      addBlurListener,
      removeBlurListener: removeCurrentListener,
    };

    // Return cleanup function that uses the local reference
    return removeCurrentListener;
  }, [handleBlur]);

  // Store functions in ref for access from event handlers
  const functionRef = useRef({
    // eslint-disable-next-line unused-imports/no-unused-vars
    addBlurListener: (element: HTMLInputElement) => {},
    removeBlurListener: () => {},
  });

  // Update hasValue when inputValue changes
  useEffect(() => {
    setHasValue(Boolean(inputValue));
  }, [inputValue]);

  // Handle focus event
  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    functionRef.current.addBlurListener(e.currentTarget);
    setOnFocus(true);

    if (inputProps?.onFocus) {
      inputProps.onFocus(e);
    }
  };

  // Handle input change
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setHasValue(Boolean(e.target.value));

    if (onChange) {
      onChange(e);
    }
  };

  const shouldShowLabel = mode === "label-on-border" && (onFocus || hasValue);

  return (
    <div className="inline-flex w-full flex-1 shrink grow basis-0 flex-col items-start justify-start gap-1">
      <div className="inline-flex h-5 items-center justify-start gap-1 self-stretch">
        {mode === "label-on-border" ? (
          <>
            {shouldShowLabel ? (
              <label
                className={clsMerge(
                  "relative -bottom-[13px] left-3 z-[1] bg-white px-1.5 text-sm font-[12px] leading-5 text-tenant-gray-dark",
                  labelClassNames,
                )}
                {...labelRestProps}
              >
                {label}
                {isOptional && <span className="ml-1 text-tenant-gray-medium">(Optional)</span>}
              </label>
            ) : (
              <label
                className={clsMerge("-bottom-4 left-3 h-[10px] w-[1px]", labelClassNames)}
                {...labelRestProps}
              ></label>
            )}
          </>
        ) : (
          <>
            <label
              className={clsMerge(
                "text-sm font-medium leading-[20px] text-tenant-gray-dark",
                labelClassNames,
              )}
              {...labelRestProps}
            >
              {label}
              {isOptional && <span className="ml-1 text-tenant-gray-medium">(Optional)</span>}
            </label>
          </>
        )}

        <span className="ml-1 text-sm font-medium text-tenant-gray-medium">{inlineHelperText}</span>
      </div>
      <div className="relative w-full">
        <input
          className={clsMerge(
            // Base styles
            "h-[46px] w-full self-stretch rounded-[8px] bg-white focus-within:outline-none",
            "text-[14px] leading-[17px]",
            "p-[16px]",
            "placeholder:text-base placeholder:font-normal placeholder:leading-[22px] placeholder:text-tenant-gray-medium",

            // Ring styles
            "ring-1 ring-tenant-gray-dim",
            "focus:ring-tenant-grey-midnight-blue",

            // Disabled styles
            "disabled:bg-tenant-gray-lightest disabled:text-tenant-gray-steel disabled:!opacity-40",

            // Error styles
            Boolean(error) && "ring-1 ring-tenant-red-primary",
            inputClassName,
          )}
          placeholder={
            mode === "label-on-border" && !shouldShowLabel
              ? `${label}${isOptional ? " (optional)" : ""}`
              : ""
          }
          value={inputValue}
          onChange={handleChange}
          onFocus={handleFocus}
          {...inputRestProps}
        />
      </div>
      {Boolean(error) && (
        <p
          className={clsMerge("mt-2 text-xs font-normal text-red-600", errorClassName)}
          {...errorRestProps}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default FormInputWithLabel;
