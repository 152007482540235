import { useAuthState } from "@artifactlabs/react-auth";
import { clsMerge } from "@artifactlabs/shared-react-utils";
import { useCallback } from "react";
import { useRecoilValue } from "recoil";

import { useWindowResize } from "@/hooks/useWindowResize";
import tenantAtom from "@/recoil/tenant";
import { getOrgAuth0ConfigByTenantId } from "@/utils/org";

export interface Props {
  className?: String;
}
const LoginButton: React.FunctionComponent<Props> = ({ className }) => {
  const tenant = useRecoilValue(tenantAtom);
  const { domain } = getOrgAuth0ConfigByTenantId(tenant?.id);
  const { login } = useAuthState(domain);
  const isMobile = useWindowResize();

  const handleClick = useCallback(() => login(), [login]);

  return (
    <>
      <button
        className={clsMerge(
          "flex w-full cursor-pointer justify-center rounded-[8px] px-[24px] py-[16px] md:py-[8px]",
          "bg-tenant-primary-default hover:bg-tenant-primary-light",
          "text-white",
          "btn-primary text-sm font-medium leading-[16px] tracking-[.02em] outline-none md:font-semibold",
          className,
        )}
        onClick={handleClick}
      >
        {isMobile ? "Login" : "LOG IN"}
      </button>
    </>
  );
};

export default LoginButton;
