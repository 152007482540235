import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent, useState } from "react";
import { PhoneInput, PhoneInputProps } from "react-international-phone";
import "react-international-phone/style.css";

interface CustomPhoneInputProps extends PhoneInputProps {
  label?: string;
  isOptional?: boolean;
  mode?: "default" | "label-on-border";
  isError?: boolean;
}

const CustomPhoneInput: FunctionComponent<CustomPhoneInputProps> = ({
  disabled,
  label,
  isOptional = false,
  mode = "default",
  onChange,
  value,
  placeholder,
  onFocus: providedOnFocus,
  isError = false,
  ...rest
}) => {
  const [onFocus, setOnFocus] = useState(false);
  const [meta, setMeta] = useState({
    inputValue: "",
  });

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    if (providedOnFocus) {
      providedOnFocus(e);
    }

    e.target.addEventListener(
      "blur",
      () => {
        setOnFocus(false);
      },
      { once: true },
    );

    setOnFocus(true);
  };

  return (
    <div>
      {mode === "label-on-border" ? (
        <label
          className={clsMerge(
            "relative bottom-[-12px] z-[1] ml-[10px] bg-white px-[6px] py-0 pb-1 text-sm leading-5 text-tenant-gray-dark",
            !onFocus && !meta?.inputValue && "opacity-0",
          )}
        >
          {label}
          {isOptional && (
            <span className="ml-1 text-sm font-medium text-tenant-gray-medium">(optional)</span>
          )}
        </label>
      ) : (
        <div className="display-block !mb-[3px]">
          <label className="text-sm font-medium leading-5 text-tenant-gray-dark">
            {label}
            {isOptional && (
              <span className="ml-1 text-sm font-medium text-tenant-gray-medium">(optional)</span>
            )}
          </label>
        </div>
      )}
      <div className={clsMerge("relative h-[46px]", "[--react-international-phone-height:46px]")}>
        <PhoneInput
          className="h-full"
          countrySelectorStyleProps={{
            buttonClassName: clsMerge(
              "border-0 h-full w-[60px] outline-none text-inherit focus:ring-0 !rounded-l-[8px] !border-tenant-gray-dim",
              onFocus && "!border-tenant-grey-midnight-blue",
              Boolean(isError) && !onFocus && "ring-1 ring-tenant-red-primary",
            ),
            dropdownStyleProps: {
              className: `w-[456px] mt-2 max-h-60 !rounded-[8px] text-inherit !z-[2] ${onFocus && "!border-tenant-grey-midnight-blue"}`,
              listItemClassName: "px-3 py-2",
            },
          }}
          dialCodePreviewStyleProps={{
            className: clsMerge(
              "border-0 w-[60px] text-inherit font-inherit text-tenant-gray-medium appearance-none !border-tenant-gray-dim",
              disabled ? "bg-tenant-gray-lightest" : "bg-white",
            ),
          }}
          disabled={disabled}
          disableDialCodeAndPrefix={true}
          inputClassName={clsMerge(
            "border-0 w-full h-full outline-none text-inherit  !rounded-r-[8px] focus:ring-0 !border-tenant-gray-dim",
            onFocus && "!border-tenant-grey-midnight-blue",
            Boolean(isError) && !onFocus && "ring-1 ring-tenant-red-primary",
            "placeholder:text-base placeholder:font-normal placeholder:leading-[22px] placeholder:text-tenant-gray-medium",
          )}
          placeholder={
            mode === "label-on-border" && !onFocus && !meta?.inputValue
              ? `${placeholder || label}${isOptional ? " (optional)" : ""}`
              : ""
          }
          value={value}
          onChange={(value, meta) => {
            setMeta({
              inputValue: meta.inputValue,
            });
            onChange && onChange(value, meta);
          }}
          onFocus={handleFocus}
          {...rest}
        />
      </div>
    </div>
  );
};

export default CustomPhoneInput;
