import { clsMerge } from "@artifactlabs/shared-react-utils";
import React from "react";
import { FunctionComponent, HTMLAttributes } from "react";
import { useRecoilValue } from "recoil";

import tenantAtom from "@/recoil/tenant/atom";
import { getOrgConfigByTenantId } from "@/utils/org";

type Props = HTMLAttributes<HTMLAnchorElement>;

const STORAGE_URL = process.env.NEXT_PUBLIC_S3_PUBLIC_ASSETS_BUCKET_HOST_NAME!;

const LicenseLinkButton: FunctionComponent<Props> = ({ className, children, ...rest }) => {
  //
  const tenant = useRecoilValue(tenantAtom);
  const config = getOrgConfigByTenantId(tenant.id);

  let licenseLink = config.licenseLink;

  //
  return (
    <a
      className={clsMerge("text-center", "py-2 pr-2", "tenant-link", className)}
      {...rest}
      href={licenseLink}
      rel="noopener noreferrer"
      target="_blank"
    >
      {children ?? "View License Terms"}
    </a>
  );
};

export default LicenseLinkButton;
