import { clsMerge } from "@artifactlabs/shared-react-utils";
import Image from "next/image";
import React from "react";

import TermsAndConditionsButton from "@/components/Button/TermsAndConditionsButton";
import Container from "@/components/Container";

const SCMPFooter: React.FunctionComponent = () => {
  return (
    <div className={clsMerge("bg-tenant-footer")}>
      <Container>
        <div className="flex flex-col justify-between gap-[40px] px-[24px] py-[32px] md:flex-row md:px-[40px]">
          {/* logo */}
          <div className="flex flex-col items-center divide-y-2 divide-tenant-primary-default md:flex-row md:divide-x-2 md:divide-y-0">
            {/* scmp logo */}
            <div className="flex h-full w-full items-center justify-center pb-[20px] md:pb-0 md:pr-[20px]">
              <div className="w-[246px] md:w-[340px]">
                <Image
                  alt="scmp-footer"
                  className="object-contain"
                  height={77}
                  src="/logos/scmp-footer.svg"
                  width={340}
                />
              </div>
            </div>
            {/* artifact logo */}
            <div className="flex h-full w-full flex-row items-center justify-center gap-[4px] pt-[20px] text-tenant-primary-default md:pl-[20px] md:pt-0">
              <div className="text-sm font-normal uppercase md:text-xs">powered by</div>
              <div className="w-[24px]">
                <Image
                  alt="artifacts-footer"
                  className="object-contain"
                  height={24}
                  src="/logos/scmp-artifactlabs-footer.svg"
                  width={24}
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col items-center gap-[16px] text-tenant-primary-default md:items-end md:gap-[12px]">
            <div className="flex flex-row items-center gap-[16px] text-sm font-medium md:items-end md:text-base">
              <a href={"https://www.scmp.com/"} target="_blank">
                About Us
              </a>
              <TermsAndConditionsButton>Terms & Conditions</TermsAndConditionsButton>
              <a href={"https://www.scmp.com/privacy-policy"} target="_blank">
                Privacy Policy
              </a>
            </div>
            <div className="flex flex-col items-center text-xs font-normal uppercase md:items-end md:text-end">
              <div>© 2024 South China Morning Post Publishers Ltd.</div>
              <div>All rights reserved.</div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default SCMPFooter;
