import { clsMerge } from "@artifactlabs/shared-react-utils";
import { FunctionComponent } from "react";

import UserProfile from "@/components/Authentication/UserProfile";

const UserProfileModalView: FunctionComponent = () => {
  return (
    <div
      className={clsMerge(
        "flex min-h-[50%] flex-1 flex-col items-center justify-start overflow-y-auto bg-white",
        "h-[100vh] w-[100vw] px-[15px] py-[40px]",
        "md:h-fit md:max-h-[95vh] md:w-[536px] md:bg-white md:p-[40px]",
      )}
    >
      <div className="max-w-[536px]">
        {/* Page Title */}
        <div
          className={clsMerge(
            "custom-theme-home-header-title h-[36px] pb-[54px] pt-[32px] text-center text-[32px] font-normal text-tenant-gray-dark",
          )}
        >
          Create your profile
        </div>

        {/* Registration Form */}
        <UserProfile className={clsMerge("mt-[40px]")} disabled={false} mode="label-on-border" />
      </div>
    </div>
  );
};

export default UserProfileModalView;
